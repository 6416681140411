import styled from 'styled-components';
import { Video } from '../../assets/icons';

export const VideoIcon = styled(Video)`
  height: 32px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  margin-right: 5px;
`;

export const RedoButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-family: ${({ theme }) => theme.fonts.default};
  padding: 14px 24px;
  width: 100%;
  border: solid;
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.grey};
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.span`
  flex-grow: 1;
  text-align: left;
`;
