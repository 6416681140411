import { useCallback } from 'react';
import { HiddenInput } from './styled';
import PropTypes from 'prop-types';
import placeholderImage from '../../assets/images/thumbnail-placeholder.jpg?base64';

const fileLimit = 1024 ** 3; // 1Gb

const VideoUpload = ({ setVideos, setVideoThumbnails, inputRef, onError }) => {
  const handleVideoChange = useCallback(
    (e) => {
      Array.from(e.target.files).forEach((file) => {
        if (file && file.size > fileLimit) {
          alert('File too large!');
          return;
        }

        if (file) {
          setVideos((previousState) => [...previousState, file]);
          generateThumbnail(file).catch(() => {
            // If thumbnail generation fails, use placeholder
            setVideoThumbnails((previousState) => [
              ...previousState,
              placeholderImage,
            ]);
          });
        }
      });
    },
    [setVideos, setVideoThumbnails],
  );

  const generateThumbnail = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        const blob = new Blob([fileReader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const videoElement = document.createElement('video');

        const timeoutId = setTimeout(() => {
          URL.revokeObjectURL(url);
          reject(new Error('Thumbnail generation timed out'));
        }, 5000); // 5 second timeout

        const handleError = () => {
          clearTimeout(timeoutId);
          URL.revokeObjectURL(url);
          onError(true);
          reject(new Error('Failed to generate thumbnail'));
        };

        videoElement.addEventListener('error', handleError);
        videoElement.addEventListener('timeupdate', function timeupdate(event) {
          try {
            snapImage(event.target);
            event.target.removeEventListener('timeupdate', timeupdate);
            event.target.pause();
            clearTimeout(timeoutId);
            resolve();
          } catch (error) {
            handleError();
          }
        });

        videoElement.preload = 'metadata';
        videoElement.src = url;
        videoElement.muted = true;
        videoElement.playsInline = true;
        videoElement.play().catch(handleError);
      };

      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  };

  const snapImage = (videoElement) => {
    const canvas = document.createElement('canvas');

    const originalWidth = videoElement.videoWidth;
    const originalHeight = videoElement.videoHeight;

    // Calculate the aspect ratio and new dimensions to fit within 400x400
    let targetWidth = 400;
    let targetHeight = 400;

    if (originalWidth > originalHeight) {
      targetHeight = (400 / originalWidth) * originalHeight;
    } else {
      targetWidth = (400 / originalHeight) * originalWidth;
    }

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const context = canvas.getContext('2d');

    context.drawImage(
      videoElement,
      0,
      0,
      originalWidth,
      originalHeight,
      0,
      0,
      targetWidth,
      targetHeight,
    );

    const image = canvas.toDataURL('image/jpeg', 0.8);
    setVideoThumbnails((previousState) => [...previousState, image]);
    URL.revokeObjectURL(videoElement.src);
  };

  const timeupdate = function (event) {
    snapImage(event.target);
    event.target.removeEventListener('timeupdate', timeupdate);
    event.target.pause();
  };

  return (
    <HiddenInput
      type='file'
      accept='video/*'
      ref={inputRef}
      onChange={handleVideoChange}
    />
  );
};

VideoUpload.propTypes = {
  setVideos: PropTypes.func.isRequired,
  setVideoThumbnails: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

export default VideoUpload;
