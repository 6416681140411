import axios from 'axios';

const backendApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

const getSignedUrls = async (urlToken, type, media, thumbnailBlobs) => {
  if (!urlToken) {
    return null;
  }

  try {
    const {
      data: { mediaUrls, thumbnailUrls, mediaUuids },
    } = await backendApi.post(`/api/public/reports/${urlToken}/media-link/`, {
      type,
      media: media.map((x) => ({
        contentType: x.type,
        contentLength: x.size,
        fileExtension: x.name.split('.').pop(),
      })),
      thumbnails: thumbnailBlobs.map((x) => ({
        contentType: x.type,
        contentLength: x.size,
        fileExtension: 'jpg',
      })),
    });

    return { mediaUrls, thumbnailUrls, mediaUuids };
  } catch (error) {
    throw new Error(`Failed to get signed urls. Reason: ${error.message}`);
  }
};

const validateToken = async (urlToken) => {
  if (!urlToken) {
    return false;
  }

  try {
    const response = await backendApi.get(`/api/public/reports/${urlToken}/`);
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return null;
  } catch {
    return null;
  }
};

const updateReport = async (urlToken, data) => {
  if (!data) {
    return false;
  }

  try {
    const response = await backendApi.put(
      `/api/public/reports/${urlToken}/`,
      data,
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    return false;
  } catch {
    return null;
  }
};

const updateMediaStatuses = async (urlToken, data) => {
  if (!data) {
    return false;
  }

  try {
    const response = await backendApi.put(
      `/api/public/reports/${urlToken}/media-statuses/`,
      data,
    );
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    return false;
  } catch {
    return null;
  }
};

const uploadMedia = async (
  type,
  token,
  media,
  thumbnailBlobs,
  setUploadProgress,
  onSignUrlError,
) => {
  if (!token || !media) {
    return false;
  }

  const { mediaUrls, thumbnailUrls, mediaUuids } = await getSignedUrls(
    token,
    type,
    media,
    thumbnailBlobs,
  );

  if (!mediaUrls) {
    if (onSignUrlError) {
      onSignUrlError();
    }
    return false;
  }

  const uploadPromises = [];

  const progressMap = {};

  const allFiles = [...media, ...thumbnailBlobs];
  const allUrls = [...mediaUrls, ...thumbnailUrls];

  for (let i = 0; i < allFiles.length; i++) {
    progressMap[i] = 0;

    const uploadMediaPromise = axios.put(allUrls[i], allFiles[i], {
      headers: { 'Content-Type': allFiles[i].type },
      onUploadProgress: (progressEvent) => {
        progressMap[i] = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        const progress = Math.round(
          Object.values(progressMap).reduce((acc, curr) => acc + curr, 0) /
            Object.values(progressMap).length,
        );
        setUploadProgress(progress);
      },
    });
    uploadPromises.push(uploadMediaPromise);
  }

  await Promise.all([...uploadPromises]);

  return await updateMediaStatuses(token, {
    mediaUploadedAt: new Date(),
    mediaUuids,
    type,
  });
};

const getOrganisation = async (uuid) => {
  if (!uuid) {
    return null;
  }

  try {
    const response = await backendApi.get(`/api/public/organisations/${uuid}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch {
    return null;
  }
};

const submitSelfServeReport = async (organisation, data) => {
  if (!data) {
    return false;
  }

  try {
    const response = await backendApi.post('/api/public/reports/', {
      ...data,
      organisation,
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch {
    throw new Error('Failed to submit report');
  }
};

export {
  validateToken,
  uploadMedia,
  updateReport,
  getOrganisation,
  submitSelfServeReport,
};
