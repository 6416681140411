import PropTypes from 'prop-types';
import { VideoIcon, RedoButton, TextWrapper } from './styled';

const Button = ({ onClick, children, ...props }) => {
  return (
    <RedoButton onClick={onClick} {...props}>
      <VideoIcon />
      <TextWrapper>{children}</TextWrapper>
    </RedoButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Button;
