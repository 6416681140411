import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProgressHeader from '../../components/ProgressHeader';
import RedoButton from '../../components/RedoButton';

import ScrollToTop from '../../components/ScrollToTop';
import FullWidthButton from '../../components/FullWidthButton';
import ContentContainer from '../../components/ContentContainer';
import {
  SelectedVideo,
  RedoContainer,
  StyledMainContainer,
  ErrorText,
} from './styled';

const VideoUploadScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.thumbnail) {
      const img = document.getElementById('thumb');
      img.src = state.thumbnail;
    }
  }, [state.thumbnail]);

  const handleSubmit = useCallback(async () => {
    navigate('/additional-info', {
      state,
    });
  }, []);

  const handleRedo = () => {
    navigate('/video-instructions', { state });
  };

  return (
    <>
      <ScrollToTop />
      <ProgressHeader step={1} />
      <StyledMainContainer>
        <ContentContainer>
          <SelectedVideo>
            <img id='thumb' alt='Video Thumbnail' />
            {state.videoThumbnailError && (
              <ErrorText>
                {t('videoUpload.error.thumbnailGeneration')}
              </ErrorText>
            )}
          </SelectedVideo>
          <RedoContainer>
            <RedoButton onClick={handleRedo} width='100%'>
              {t('common.redo')}
            </RedoButton>
          </RedoContainer>
        </ContentContainer>
      </StyledMainContainer>
      <FullWidthButton type='button' onClick={handleSubmit}>
        {t('common.next')}
      </FullWidthButton>
    </>
  );
};

export default VideoUploadScreen;
