import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import MainContainer from '../../components/MainContainer';
import ContentContainer from '../../components/ContentContainer';
import TitleLeft from '../../components/TitleLeft';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import { getOrganisation, submitSelfServeReport } from '../../api';
import {
  Modal,
  ModalContent,
  Form,
  Input,
  Select,
  ErrorMessage,
} from './styled';

const ISSUE_TYPES = [
  'Alteration',
  'Complaint',
  'Disconnection',
  'General Enquiry',
  'New connection',
  'Repair',
];

const SelfServeScreen = () => {
  const { t } = useTranslation();
  const { organisation } = useParams();
  const [organisationObject, setOrganisationObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    referenceNo: '',
    customerName: '',
    customerPhoneNumber: '',
    customerEmail: '',
    type: '',
  });

  useEffect(() => {
    const fetchOrganisation = async () => {
      try {
        const data = await getOrganisation(organisation);
        setOrganisationObject(data);
      } catch (error) {
        setError(t('common.invalidUrl'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganisation();
  }, [organisation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitSelfServeReport(organisation, formData);
      setShowModal(false);
      setSubmitSuccess(true);
    } catch (error) {
      setError('Something went wrong, please try again.');
    }
  };

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  if (!organisation) {
    return <div>{t('common.invalidUrl')}</div>;
  }

  if (submitSuccess) {
    return (
      <>
        <Header logoSrc={organisationObject.logo} />
        <MainContainer>
          <ContentContainer>
            <TitleLeft>{t('selfServe.thankYou.title')}</TitleLeft>
            <Paragraph>{t('selfServe.thankYou.description')}</Paragraph>
          </ContentContainer>
        </MainContainer>
      </>
    );
  }

  return (
    <>
      <Header logoSrc={organisationObject.logo} />
      <MainContainer>
        <ContentContainer>
          <TitleLeft>{t('selfServe.title')}</TitleLeft>
          <Paragraph>{t('selfServe.description')}</Paragraph>
          <Button onClick={() => setShowModal(true)}>
            {t('selfServe.submitButton')}
          </Button>
        </ContentContainer>
      </MainContainer>

      {showModal && (
        <Modal onClick={() => setShowModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <Form onSubmit={handleSubmit}>
              <Input
                type='text'
                name='referenceNo'
                placeholder={t('selfServe.form.referenceNo')}
                value={formData.referenceNo}
                onChange={handleChange}
                required
              />
              <Input
                type='text'
                name='customerName'
                placeholder={t('selfServe.form.customerName')}
                value={formData.customerName}
                onChange={handleChange}
                required
              />
              <Input
                type='tel'
                name='customerPhoneNumber'
                placeholder={t('selfServe.form.phoneNumber')}
                value={formData.customerPhoneNumber}
                onChange={handleChange}
                required
              />
              <Input
                type='email'
                name='customerEmail'
                placeholder={t('selfServe.form.email')}
                value={formData.customerEmail}
                onChange={handleChange}
                required
              />
              <Select
                name='type'
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value=''>{t('selfServe.form.issueType')}</option>
                {ISSUE_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Button type='submit'>{t('common.submit')}</Button>
            </Form>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SelfServeScreen;
